@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'transition-style';
@import 'react-perfect-scrollbar/dist/css/styles.css';

@font-face {
  font-family: 'Roboto';
  src: url(/assets/theme/buehler/fonts/Roboto-Regular.woff2) format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Black';
  src: url(/assets/theme/buehler/fonts/Roboto-Black.woff2) format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url(/assets/theme/buehler/fonts/Roboto-Medium.woff2) format('woff2');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'notoserif';
  src: url(/assets/theme/buehler/fonts/notoserif-regular-webfont.woff2) format('woff2');
  font-display: swap;
}

h1,
h2,
h3,
h4,
h4,
h5 {
  font-family: 'notoserif';
}

body {
  overflow: hidden;
}

:root {
  --foreground-rgb: 60, 60, 60;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --swiper-pagination-color: #009b91;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 1);
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 30, 30, 30;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body,
#__next {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb)))
    rgb(var(--background-start-rgb));
  background: #000;
}

/*  Side Menu */
@keyframes openSubMenu {
  from {
    max-height: 0;
  }

  to {
    max-height: 1200px;
  }
}
@keyframes closeSubMenu {
  from {
    max-height: 1200px;
  }

  to {
    max-height: 0;
  }
}

.top-box {
  box-shadow: inset 0 7px 12px -7px rgba(0, 0, 0, 0.4);
}
.left-box {
  box-shadow: inset 7px 0 12px -7px rgba(0, 0, 0, 0.4);
}
.right-box {
  box-shadow: inset -7px 0 12px -7px rgba(0, 0, 0, 0.4);
}
.bottom-box {
  box-shadow: inset 0 -7px 12px -7px rgba(0, 0, 0, 0.4);
}

.menu.is-submenu,
.menu.is-submenu.is-closed {
  overflow: hidden;
  max-height: 0;
}

.menu.is-submenu.is-closed {
  animation: closeSubMenu 0.4s;
}

.menu.is-submenu.is-open {
  max-height: 1200px;
  animation: openSubMenu 1.4s;
}

/* .menu.is-submenu li:first-of-type .menu-btn {
  box-shadow: inset 0 7px 12px -7px rgba(0, 0, 0, 0.4);
}
.menu.is-submenu li:last-of-type .menu-btn {
  box-shadow: inset 0 -7px 12px -7px rgba(0, 0, 0, 0.4);
} */

.menu.is-submenu.is-open {
  box-shadow:
    inset 0px 11px 22px -22px rgba(0, 0, 0, 0.5),
    inset 0px -11px 22px -22px rgba(0, 0, 0, 0.5);
}

.animate.pop {
  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animate.fade-in {
  opacity: 0;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-name: fade-in;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes circle-in-center {
  from {
    clippath: circle(0%);
  }
  to {
    clippath: circle(125%);
  }
}

[transition-style='in:circle:center'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) circle-in-center both;
}

@keyframes wipe-out-top-right {
  from {
    clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
  }
  to {
    clip-path: polygon(50% -50%, 150% 50%, 150% 50%, 50% -50%);
  }
}

[transition-style='out:wipe:top-right'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-out-top-right both;
}
@keyframes circle-in-hesitate {
  0% {
    clip-path: circle(0%);
  }
  40% {
    clip-path: circle(40%);
  }
  100% {
    clip-path: circle(125%);
  }
}

[transition-style='in:circle:hesitate'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) circle-in-hesitate both;
}

@keyframes wipe-out-right {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 100%);
  }
}

[transition-style='out:wipe:right'] {
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-out-right both;
}

@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style='in:wipe:right'] {
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
}

@keyframes circle-out-hesitate {
  0% {
    clip-path: circle(125%);
  }
  40% {
    clip-path: circle(40%);
  }
  100% {
    clip-path: circle(0%);
  }
}

@keyframes wipe-in-up {
  from {
    clip-path: inset(100% 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style='in:wipe:up'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-up both;
}

[transition-style='out:circle:hesitate'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) circle-out-hesitate both;
}

@keyframes circle-in-center {
  from {
    clip-path: circle(0%);
  }
  to {
    clip-path: circle(125%);
  }
}

[transition-style='in:circle:center'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) circle-in-center both;
}

@keyframes out-circle-swoop {
  from {
    clip-path: var(--circle-bottom-right-in);
  }
  to {
    clip-path: var(--circle-top-right-out);
  }
}

[transition-style='out:custom:circle-swoop'] {
  --transition__duration: 1.25s;
  --transition__easing: cubic-bezier(0.3, 1, 0.25, 1);
  animation-name: out-circle-swoop;
}

@keyframes wipe-in-bottom-right {
  from {
    clip-path: polygon(0 0, 0 0, 0 0, 0 50%);
  }
  to {
    clip-path: polygon(-50% 0%, 200% 0, 0 200%, 0 -50%);
  }
}

[transition-style='in:wipe:bottom-right'] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-bottom-right both;
}

@keyframes in-circle-swoop {
  from {
    clip-path: var(--circle-top-left-out);
  }
  to {
    clip-path: var(--circle-bottom-right-in);
  }
}

[transition-style='in:custom:circle-swoop'] {
  --transition__duration: 2.5s;
  animation-name: in-circle-swoop;
}

@keyframes out-circle-swoop {
  from {
    clip-path: var(--circle-bottom-right-in);
  }
  to {
    clip-path: var(--circle-top-left-out);
  }
}

[transition-style='out:custom:circle-swoop'] {
  --transition__duration: 0.6s;
  --transition__easing: cubic-bezier(0.3, 1, 0.25, 1);
  animation-name: out-circle-swoop;
}

.flyout-menu {
  clip-path: var(--circle-top-left-out);
}

@keyframes fade-in-menu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rollout-menu {
  from {
    max-height: 0;
  }
  to {
    max-height: 1200px;
  }
}

.main-menu {
  /* max-height: 0; */
}
.menu-wrapper {
  opacity: 0;
}

.main-menu.is-open {
}

.main-menu .menu-wrapper-h {
  max-height: 0;
}

.main-menu.is-open .menu-wrapper-h {
  animation: rollout-menu 0.4s ease-out;
  /* animation-name: rollout-menu; */
  animation-delay: 0.3s;
  animation-fill-mode: forwards;

  /* opacity: 1; */
}

.main-menu .menu-wrapper-fade {
  opacity: 0;
}

.main-menu.is-open .menu-wrapper-fade {
  animation: fade-in-menu 0.3s ease-out;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  /* opacity: 1; */
}

/* Swiper SLider */

:root {
  --swiper-navigation-size: 10px;
  --swiper-theme-color: yellow;
  --swiper-navigation-size: 24px;
  /* --swiper-pagination-color: white;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px; */
}

/* .swiper-button-disabled {
  display: none !important;
} */

.swiper-button-prev {
  background: white;
  border-radius: 0.75rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 20px;
  padding-left: 10px;
  height: 80px !important;
  background-size: 14px;
  left: 0 !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.swiper-button-prev:after {
  font-size: 28px !important;
  color: #009b91;
}

.swiper-button-next {
  background: white;
  border-radius: 0.75rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 20px;
  padding-right: 10px;
  height: 80px !important;
  background-size: 14px;
  right: 0 !important;
  box-shadow: 10px 5px 15px rgba(0, 0, 0, 0.3);
}

.swiper-button-next:after {
  font-size: 28px !important;
  color: #009b91;
}

@keyframes bring-content-viewer {
  from {
    /* transform: translateY(5%); */
    opacity: 0;
  }
  to {
    /* transform: translateY(0); */
    opacity: 1;
  }
}

.content-viewer.is-open {
  animation: bring-content-viewer 0.4s ease-out;
  /* animation-name: rollout-menu; */
  /* animation-delay: 0.3s; */
  animation-fill-mode: forwards;
}

@keyframes bring-content {
  from {
    transform: translateY(5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.content-viewer .content {
  opacity: 0;
}

.content-viewer.is-open .content {
  animation: bring-content-viewer 0.4s ease-out;
  /* animation-name: rollout-menu; */
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

/* prefect-scrollbar */
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  z-index: 1;
}

.cue-video-story-wrapper .cue-video-tour-steps {
  @media screen and (min-width: 700px) {
    width: calc(100% - 140px);
    padding: 5px 0 5px 140px;
  }
}
